'use client';

import React from 'react';
import classnames from 'classnames';
import TypographyMUI from '@mui/material/Typography';
import type { CustomTypographyProps } from './types.d';
import styles from './Typography.module.scss';
const typographyClasses = {
  root: styles.root,
  h1: styles.h1,
  h1bk: styles.h1bk,
  h2: styles.h2,
  h2sb: styles.h2sb,
  h2bk: styles.h2bk,
  h3: styles.h3,
  h4: styles.h4,
  h4bk: styles.h4bk,
  h5: styles.h5,
  h6: styles.h6,
  h6sb: styles.h6sb,
  h7: styles.h7,
  h7sb: styles.h7sb,
  h8: styles.h8,
  h8bk: styles.h8bk,
  h8sb: styles.h8sb,
  h9: styles.h9,
  h9bk: styles.h9bk,
  h10: styles.h10,
  h10bk: styles.h10bk,
  h10sb: styles.h10sb,
  h11: styles.h11,
  h11bk: styles.h11bk,
  h12: styles.h12,
  h12bk: styles.h12bk,
  label8: styles.label8,
  label9: styles.label9,
  label10: styles.label10,
  label11: styles.label11,
  label12: styles.label12,
  paragraph7: styles.paragraph7,
  paragraph8: styles.paragraph8,
  paragraph9: styles.paragraph9,
  paragraph10: styles.paragraph10,
  paragraph10bk: styles.paragraph10bk,
  paragraph12: styles.paragraph12,
  mono1: styles.mono1,
  mono2: styles.mono2
};
const Typography: React.FC<CustomTypographyProps> = ({
  children,
  color = 'default',
  className,
  typographyRef,
  adaptive = true,
  ...props
}) => {
  return <TypographyMUI {...props} ref={typographyRef} classes={typographyClasses} className={classnames(className, {
    [styles.adaptive]: adaptive,
    [styles.colorDefault]: color === 'default',
    [styles.colorPrimary]: color === 'primary',
    [styles.colorSecondary]: color === 'secondary',
    [styles.colorWhite]: color === 'white',
    [styles.colorGray]: color === 'gray',
    [styles.colorPositive]: color === 'positive',
    [styles.colorNegative]: color === 'negative',
    [styles.colorBrand]: color === 'brand'
  })} data-sentry-element="TypographyMUI" data-sentry-component="Typography" data-sentry-source-file="Typography.tsx">
      {children}
    </TypographyMUI>;
};
export default Typography;