import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './Paper.module.scss';
const Paper: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return <div className={classnames(styles.root, className)} {...props} data-sentry-component="Paper" data-sentry-source-file="Paper.tsx">
      {children}
    </div>;
};
export default Paper;