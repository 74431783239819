'use client';

import React, { HTMLAttributes } from 'react';
import Image, { ImageProps } from 'next/image';
import cx from 'classnames';
import { useTranslations } from 'next-intl';
import useToggle from 'hooks/useToggle';
import { ReactComponent as VerifyIcon } from 'public/assets/icons/verify.svg';
import { ReactComponent as ShieldIcon } from 'public/assets/icons/shield.svg';
import Typography from 'components/Typography';
import styles from './ChannelAvatar.module.scss';
interface ChannelAvatarProps extends Omit<ImageProps, 'src'> {
  size: '240' | '128' | '96' | '64' | '48' | '32' | '24' | '18';
  src: string | null;
  alt: string;
  name: string;
  verified?: boolean;
  context?: 'default' | 'paper' | 'transparent';
  confirmed?: boolean;
}
const colors = ['#3498db', '#2ecc71', '#e74c3c', '#e67e22', '#9b59b6'];
const getColot = (name: string) => {
  const words = name.replaceAll(/[^A-Za-zА-Яа-я0-9\s]/g, ' ').replaceAll(/\s+/g, ' ').split(' ');
  let letters = '';
  if (words.length === 1) {
    letters = words[0].slice(0, 2).toUpperCase();
  } else {
    const [firstWord, secondWord] = words;
    letters = `${firstWord.slice(0, 1)}${secondWord.slice(0, 1)}`.toUpperCase();
  }
  const indexSum = letters.split('').map(char => char.charCodeAt(0)).reduce((acc, curr) => acc + curr, 0);
  return {
    color: colors[indexSum % colors.length],
    title: letters
  };
};
const ChannelAvatar: React.FC<ChannelAvatarProps & HTMLAttributes<HTMLDivElement>> = ({
  src,
  alt,
  size,
  verified,
  confirmed,
  name,
  context = 'default',
  className,
  ...props
}) => {
  const [error, setError] = useToggle(false);
  const meta = name ? getColot(name) : {
    title: name,
    color: '#3e3e3e'
  };
  const t = useTranslations('Channel');
  return <div className={cx(styles.root, className, {
    [styles.size240]: size === '240',
    [styles.size128]: size === '128',
    [styles.size96]: size === '96',
    [styles.size64]: size === '64',
    [styles.size48]: size === '48',
    [styles.size32]: size === '32',
    [styles.size24]: size === '24',
    [styles.size18]: size === '18',
    [styles.defaultContext]: context === 'default',
    [styles.paperContext]: context === 'paper',
    [styles.transparentContext]: context === 'transparent'
  })} data-sentry-component="ChannelAvatar" data-sentry-source-file="ChannelAvatar.tsx">
      {!!verified && <VerifyIcon className={styles.verify} width={40} height={40} />}
      {!!confirmed && <Typography variant="h10" className={styles.confirmed}>
          {t('confirmed')}
          <ShieldIcon className={styles.confirmedIcon} width={16} height={16} />
        </Typography>}
      {!src || error ? <div className={cx(styles.avatar, styles.placeholder)}
    // @ts-ignore
    style={{
      '--avatar-color': meta.color
    }}>
          {meta.title}
        </div> : <Image src={src} alt={alt} width={size} height={size} className={styles.avatar} onError={setError} {...props} />}
    </div>;
};
export default ChannelAvatar;