import React from 'react';
import cx from 'classnames';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import styles from './Button.module.scss';
const Button: React.FC<ButtonProps> = ({
  children,
  className,
  classes = {},
  rounded = false,
  iconned = false,
  ...props
}) => {
  return <MuiButton {...props} classes={{
    root: cx(styles.root, classes?.root, {
      [styles.rounded]: rounded,
      [styles.iconned]: iconned
    }),
    startIcon: styles.startIcon,
    primary: styles.primary,
    light: styles.light,
    secondary: styles.secondary,
    text: styles.text,
    sizeLarge: styles.large,
    sizeMedium: styles.medium,
    sizeSmall: styles.small
  }} className={cx(className, {
    [styles.light]: props.variant === 'light'
  })} disableRipple data-sentry-element="MuiButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {children}
    </MuiButton>;
};
export default Button;