import { createLocalizedPathnamesNavigation, Pathnames } from 'next-intl/navigation';
import { languages } from 'constants/language';

export const pathnames = {
  '/': '/',
  '/login': '/login',
  '/channels': '/channels',
  '/groups': '/groups',
  '/posts': '/posts',
  '/topics': '/topics',
  '/topic/[id]': '/topic/[id]',
  '/cities': '/cities',
  '/city/[id]': '/city/[id]',
  '/region/[id]': '/region/[id]',
  '/channel/add': '/channel/add',
  '/channel/[id]': '/channel/[id]',
  '/channel/[id]/advertising-effectiveness': '/channel/[id]/advertising-effectiveness',
  '/channel/[id]/audience': '/channel/[id]/audience',
  '/channel/[id]/posts': '/channel/[id]/posts',
  '/channel/[id]/stats': '/channel/[id]/stats',
  '/channel/[id]/verify': '/channel/[id]/verify',
  '/channel/[id]/history': '/channel/[id]/history',
  '/channel/[id]/[postId]': '/channel/[id]/[postId]',
  '/channel/[id]/[postId]/stats/citation': '/channel/[id]/[postId]/stats/citation',
  '/channel/[id]/[postId]/stats/reactions': '/channel/[id]/[postId]/stats/reactions',
  '/channel/[id]/[postId]/stats/subscribers': '/channel/[id]/[postId]/stats/subscribers',
  '/channel/[id]/[postId]/stats/views': '/channel/[id]/[postId]/stats/views',

  /*
   * USER SECTION
   */
  '/my-account': '/my-account',
  '/my-channels': '/my-channels',
} satisfies Pathnames<typeof languages>;

export const { Link, redirect, usePathname, useRouter } = createLocalizedPathnamesNavigation({
  locales: languages,
  pathnames,
});
