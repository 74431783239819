export enum Language {
  UA = 'uk',
  EN = 'en',
  RU = 'ru',
  UZ = 'uz',
  BY = 'by',
  HI = 'hi',
  ZH = 'zh',
  KK = 'kk',
  KY = 'ky',
}

export const languages = [
  Language.UA,
  Language.EN,
  Language.RU,
  Language.UZ,
  Language.BY,
  Language.HI,
  Language.ZH,
  Language.KK,
  Language.KY,
] as const;
